<template>
  <div class="businessInfo">
    <el-form
      label-width="80px"
      label-position="left"
      size="small"
      v-if="businessInfo"
    >
      <el-form-item label="法人">
        <div class="info">{{ businessInfo.legalPerson || "- -" }}</div>
      </el-form-item>
      <el-form-item label="负责人">
        <div class="info">{{ businessInfo.aiChaStatus || "- -" }}</div>
      </el-form-item>
      <!-- <el-form-item label="公司名称">
                <div class="info">{{ businessInfo.companyName || '--' }}</div>
            </el-form-item> -->
      <el-form-item label="公司地址">
        <div class="info">{{ businessInfo.companyAddress || "- -" }}</div>
      </el-form-item>
      <el-form-item label="公司电话">
        <div class="info">{{ businessInfo.contactsStr || "- -" }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "",
  props: ["businessInfo", "wash"],
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.businessInfo {
  background-color: #fff;
  padding: 0px 15px 0 30px;
  box-sizing: border-box;
  width: 100%;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
    }
    /deep/ .el-form-item__label {
      font-weight: 600 !important;
    }
  }
}
.businessInfo .info {
  padding-left: 10px;
  width: 270px;
  box-sizing: border-box;
  height: 100%;
}
</style>
