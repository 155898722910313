<template>
    <div class="database_detail">
        <div
            class="top_title"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="title_box">
                <i class="iconfont icon-huikuan"></i>
                资料详情
                <el-button @click="goBack">
                    <i class="el-icon-arrow-left"></i> 返回列表
                </el-button>
            </div>
            <div>
                <div style="flex: 1">
                    <span class="zl"><i>资料</i></span
                    >归属人：{{ data.followAdminName }}
                    <span style="margin: 0 20px 0 20px">
                        <el-tooltip placement="bottom" v-if="wash == 1">
                            <div slot="content" class="tool_con">
                                <p v-if="data.enterpriseNameDO">
                                    <i
                                        :class="
                                            data.enterpriseNameDO.aiChaStatus ==
                                            0
                                                ? 'iconfont icon-shanchu1'
                                                : 'iconfont icon-chenggong'
                                        "
                                        style="vertical-align: -1px"
                                    ></i
                                    >工商信息清洗
                                </p>
                                <div
                                    v-for="item in data.libraryDomainBOList"
                                    :key="item.id"
                                >
                                    <p>{{ item.domain }}</p>
                                    <p
                                        style="padding-left: 10px"
                                        v-if="item.enterpriseDomainDO"
                                    >
                                        <i
                                            :class="
                                                item.enterpriseDomainDO
                                                    .whoisStatus == 0
                                                    ? 'iconfont icon-shanchu1'
                                                    : 'iconfont icon-chenggong'
                                            "
                                            style="vertical-align: -1px"
                                        ></i
                                        >域名清洗
                                    </p>
                                    <p
                                        style="padding-left: 10px"
                                        v-if="item.enterpriseDomainDO"
                                    >
                                        <i
                                            :class="
                                                item.enterpriseDomainDO
                                                    .icpStatus == 0
                                                    ? 'iconfont icon-shanchu1'
                                                    : 'iconfont icon-chenggong'
                                            "
                                            style="vertical-align: -1px"
                                        ></i
                                        >备案清洗
                                    </p>
                                </div>
                            </div>
                            <span
                                ><i
                                    style="vertical-align: -1px"
                                    class="iconfont icon-chenggong green"
                                ></i>
                                {{ '已清洗' }}</span
                            >
                        </el-tooltip>
                        <span v-else>
                            <i
                                style="vertical-align: -1px"
                                class="iconfont icon-apptubiao-"
                            ></i>
                            {{ '未清洗' }}
                        </span>
                        <em
                            v-if="
                                wash == 1 &&
                                data.enterpriseNameDO &&
                                data.enterpriseNameDO.updateTime
                            "
                        >
                            {{ data.enterpriseNameDO.updateTime }}清洗</em
                        >
                    </span>
                    <span>最后编辑时间：{{ data.updateTime }}</span>
                    <div class="label">
                        <span style="margin-right: 16px">资料标签</span>
                        <span
                            :key="index"
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            class="labelList"
                            v-for="(item, index) in $choiceLabel.state
                                .labelList"
                            >{{ item.tagName }}
                            <span class="cancel" @click="onDeleteLabel(item)">
                                <span class="el-icon-close"></span>
                            </span>
                        </span>
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            @click="onChoiceLabel()"
                            style="
                                background: #2370eb;
                                border-color: #2370eb;
                                color: #fff;
                                padding: 5px;
                                margin-top: -2px;
                            "
                        ></el-button>
                    </div>
                </div>

                <span @click="setStatus" class="Mark" style="margin-right: 8px">
                    <i
                        :class="
                            data.mark == 0
                                ? 'iconfont icon-star'
                                : 'iconfont icon-shoucang'
                        "
                        :style="{
                            color: data.mark == 0 ? '#c9c9c9' : '#ffa802',
                            position: 'relative',
                        }"
                    ></i>
                    {{ data.mark == 0 ? '设为星标' : '取消星标' }}</span
                >
                <span
                    @click="delData"
                    style="color: #d0021b"
                    v-if="btnP.dbUpdateDelete"
                    ><i class="iconfont icon-shanchutianchong"></i>删除</span
                >
            </div>
        </div>
        <div class="detail_main">
            <div class="left_box">
                <div class="left_title_box">公司信息</div>
                <MeargerForm
                    :disabled="disabled"
                    :wash="wash"
                    ref="meargerForm"
                ></MeargerForm>
                <p class="business_info">
                    <span>工商信息</span>
                    <!-- <span v-if="!data.enterpriseNameDO" style="margin-left:10px;color:red"
          >未获取到公司信息</span
        > -->
                    <!-- <span class="hide_btn" @click="changeStatus"
            >{{ tag ? "收起" : "展开" }}
            <i :class="tag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i
          ></span> -->
                </p>
                <BusinessInfo
                    :wash="wash"
                    :businessInfo="data.enterpriseNameDO"
                ></BusinessInfo>
            </div>
            <div class="right_box">
                <el-collapse v-model="activeNames">
                    <el-collapse-item :name="1">
                        <template slot="title">
                            <span class="record" style="font-weight: 600"
                                >往来记录</span
                            >
                            <p class="title">
                                <span
                                    @click.stop="addDealing"
                                    v-if="!disabled && btnP.dbDealings"
                                    >+ 添加往来</span
                                >
                            </p>
                        </template>
                        <DealingHistory
                            :disabled="disabled"
                            ref="dealingHistory"
                            :historyData="dealingHistoryList"
                            @addExplain="addExplain"
                        ></DealingHistory>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="footer_btn">
            <el-button
                size="mini"
                @click="onClose"
                round
                style="padding: 5px 20px"
                >返 回</el-button
            >
            <el-button
                type="primary"
                size="mini"
                round
                @click="onSubmit()"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    padding: 5px 20px;
                "
                >保 存</el-button
            >
        </div>
        <!--  添加往来 -->
        <el-dialog
            :visible.sync="dialogDealingsVisible"
            width="560px"
            :before-close="handleDealingClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加往来
                </div>
            </template>
            <AddDealing @close="handleDealingClose" :id="data.id" />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>

<script>
import {
    setStatus,
    libraryUpdate,
    dealingAdd,
    dealingDesAdd,
    dealingList,
    libraryGet,
} from '@/api/database/database';
import { relationList, relationDelete } from '@/api/user/label.js';
import MeargerForm from './components/mergerForm.vue';
import BusinessInfo from './components/businessInfo';
import AddDealing from './components/addDealing.vue';
import DealingHistory from '../dealings/components/history.vue';
import ChoiceLabel from '../../../components/choice-label/choiceLabel.vue';
import AddLable from '../../../components/choice-label/addLabel.vue';
export default {
    props: {},
    data() {
        return {
            data: {},
            tag: true,
            disabled: false,
            wash: 0,
            activeNames: [1],
            btnP: {},
            dealingHistoryList: [],
            dialogDealingsVisible: false,
            dialogAddlabel: false,
            dialogChoice: false,
            isGet: false,
            waitData: {
                libraryDomainBOList: [],
                libraryContactBOList: [],
            },
        };
    },
    components: {
        MeargerForm,
        BusinessInfo,
        AddDealing,
        DealingHistory,
        ChoiceLabel,
        AddLable,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            this.libraryGet(this.$route.query.id);
            this.getLabelData();
        },
        libraryGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            libraryGet(data).then((res) => {
                sessionStorage.setItem('cusAdminId', res.data.followAdminId);
                if (res.code == 200) {
                    let num = 0;
                    if (
                        res.data.enterpriseNameDO &&
                        res.data.enterpriseNameDO.updateTime
                    ) {
                        num = 1;
                    }
                    if (this.btnP.dbUpdate) {
                        setTimeout(() => {
                            this.getData(res.data, false);
                            this.tag = res.data.enterpriseNameDO ? true : false;
                            this.getDealingList(res.data);
                            this.wash = num == 0 ? 0 : 1;
                        }, 0);
                    } else {
                        setTimeout(() => {
                            this.getData(res.data, true);
                            this.tag = res.data.enterpriseNameDO ? true : false;
                            this.getDealingList(res.data);
                            this.wash = num == 0 ? 0 : 1;
                        }, 0);
                    }
                }
            });
        },
        getData(data, disabled) {
            this.data = data;
            this.disabled = disabled;
            setTimeout(() => {
                let data1 = JSON.stringify([data]);
                this.waitData = Object.assign({}, JSON.parse(data1)[0]);
                JSON.parse(data1).forEach((item, index) => {
                    if (index > 0) {
                        this.waitData.libraryContactBOList = this.MergeArray(
                            this.waitData.libraryContactBOList,
                            item.libraryContactBOList
                        );
                        this.waitData.libraryDomainBOList = this.MergeArray(
                            this.waitData.libraryDomainBOList,
                            item.libraryDomainBOList
                        );
                        // this.waitData.libraryContactBOList = this.waitData.libraryContactBOList.concat(
                        //   item.libraryContactBOList
                        // );
                        // this.waitData.libraryDomainBOList = this.waitData.libraryDomainBOList.concat(
                        //   item.libraryDomainBOList
                        // );
                    }
                });

                if (this.waitData.libraryContactBOList.length == 0) {
                    this.waitData.libraryContactBOList = [{}];
                }
                if (this.waitData.libraryDomainBOList.length == 0) {
                    this.waitData.libraryDomainBOList = [{}];
                }
                this.waitData.libraryDomainBOList.forEach((item) => {
                    item.tag = true;
                });
                // console.log(this.waitData);
                this.$refs.meargerForm.$data.waitData = this.waitData;
            }, 0);
        },
        MergeArray(arr1, arr2) {
            // console.log(arr1, arr2);
            if (arr1.length == 0 && arr2.length == 0) {
                return [{}];
            }
            var _arr = new Array();
            for (var i = 0; i < arr1.length; i++) {
                _arr.push(arr1[i]);
            }
            for (var i = 0; i < arr2.length; i++) {
                var flag = true;
                for (var j = 0; j < arr1.length; j++) {
                    if (arr2[i] == arr1[j]) {
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    _arr.push(arr2[i]);
                }
            }
            return _arr;
        },
        // 设置星标
        setStatus() {
            let mark = this.data.mark == 0 ? 1 : 0;
            let data = {
                param: {
                    id: this.data.id,
                    mark: mark,
                },
            };
            setStatus(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.data.mark = this.data.mark == 0 ? 1 : 0;
                }
            });
        },
        handleClose() {
            this.dialogChoice = false;
            this.getLabelData();
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        handleDealingClose() {
            this.dialogDealingsVisible = false;
            this.getDealingList(this.data);
        },
        // 往来列表
        getDealingList(row) {
            let data = {
                param: {
                    libraryId: row.id,
                },
            };
            dealingList(data).then((res) => {
                if (res.code == 200) {
                    this.dealingHistoryList = res.data.list;
                }
            });
        },
        // 添加往来弹窗
        addDealing() {
            this.dialogDealingsVisible = true;
        },
        // 往来添加说明
        addExplain(data) {
            dealingDesAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getDealingList({ id: this.data.id });
                }
            });
        },
        // // 往来添加
        // dealingAdd(data) {
        //   let data
        //   data.param.libraryId = this.data.id;
        //   dealingAdd(data).then((res) => {
        //     if (res.code == 200) {
        //       this.$message.success("操作成功");
        //       this.getDealingList(this.$refs.dealings.$data.data);
        //     }
        //   });
        // },
        delData() {
            this.$emit('delData', this.data);
        },
        changeStatus() {
            this.tag = !this.tag;
        },
        onSubmit(num = 0) {
            let waitData = this.$refs.meargerForm.$data.waitData;
            let domainList = waitData.libraryDomainBOList.map((item) => {
                return {
                    domain: item.domain,
                };
            });
            if (!waitData.companyName) {
                return this.$message.error('请输入公司名称');
            }
            // for (let i in domainList) {
            //     if (!domainList[i].domain) {
            //         return this.$message.error('请输入域名');
            //     }
            // }
            let data = {
                param: {
                    libraryDO: {
                        id: waitData.id,
                        companyName: waitData.companyName,
                        userNo: waitData.userNo,
                        qq: waitData.qq,
                        weChat: waitData.weChat,
                        email: waitData.email,
                        remark: waitData.remark,
                        companyDomain: waitData.companyDomain,
                        contactWay: waitData.contactWay,
                        status: waitData.status,
                    },
                    libraryDomainDOList: waitData.libraryDomainBOList.map(
                        (item) => {
                            return {
                                domain: item.domain,
                            };
                        }
                    ),
                },
            };
            for (let i in waitData.libraryContactBOList) {
                if (waitData.libraryContactBOList[i].contactContent) {
                    data.param.libraryContactDOList =
                        waitData.libraryContactBOList;
                } else {
                    data.param.libraryContactDOList = [];
                }
            }
            libraryUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    if (num) {
                        return;
                    }
                    this.onClose();
                }
            });
        },
        // 获取绑定标签
        getLabelData() {
            let data = {
                param: {
                    bandingId: this.$route.query.id,
                    bandingTableType: 6,
                },
            };
            if (!this.btnP.depSearch) {
                data.param.adminId = Number(sessionStorage.getItem('adminId'));
            }
            relationList(data).then((res) => {
                this.$choiceLabel.commit(
                    'getLabelList',
                    JSON.stringify(res.data.list)
                );
            });
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 选择标签
        onChoiceLabel() {
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '6');
                this.$choiceLabel.commit('getBindingType', '6');
                this.$choiceLabel.commit('getIsDetails', true);
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
            });
        },
        onClose() {
            this.$router.go(-1);
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    destroyed() {
        this.$choiceLabel.commit('emptyData');
        sessionStorage.removeItem('cusAdminId');
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.database_detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    .Mark {
        cursor: pointer;
        .icon-shoucang:before {
            left: -20px;
            top: 0px;
        }
    }
    .top_title {
        font-size: 14px;
        padding: 18px 15px 15px 15px;
        width: 100%;
        margin-bottom: 12px;
        box-sizing: border-box;
        .title_box {
            display: block;
            line-height: 1.2;
            margin-bottom: 15px;
            font-size: 16px;
            color: #333;
            > i {
                color: #2370eb;
                font-size: 18px;
            }
            button {
                background: rgb(255, 255, 255);
                color: rgb(35, 112, 235);
                border-color: rgb(35, 112, 235);
                padding: 5px 10px;
                float: right;
            }
        }
        div {
            display: flex;
        }
        div {
            display: flex;

            > .zl {
                color: #fff;
                width: 20px;
                height: 20px;
                background: #2370eb;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                i {
                    font-size: 16px;
                    display: inline-block;
                    font-style: normal;
                    transform: scale(0.5, 0.5);
                    white-space: nowrap;
                    line-height: 1.2;
                }
            }
            // span:last-child {
            //   flex: 1;
            //   text-align: right;
            // }
        }
        // .center_info {
        //   flex: 1;
        //   margin-left: 10px;
        //   font-size: 14px;
        //   p:first-child {
        //     margin-bottom: 8px;
        //   }
        // }
    }
    .detail_main {
        display: flex;
        justify-content: space-evenly;
        flex: 1;
        overflow: hidden;
        background-color: #f0f2f4;
        .left_box {
            flex: 2;
            padding: 0;
            margin-right: 10px;
            .left_title_box {
                height: 60px;
                border-bottom: 1px solid #e5e5e5;
                line-height: 60px;
                position: relative;
                font-size: 14px;
                font-weight: bold;
                padding-left: 30px;
            }
            .left_title_box:before {
                content: '';
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 2px;
                background: #2370eb;
            }
        }
        > div {
            padding: 15px 0px 15px 30px;
            box-sizing: border-box;
            height: 100%;
            background-color: #fff;
            overflow-y: auto;
        }
        .right_box {
            padding: 0;
            flex: 1;
            .record {
                padding-left: 30px;
                position: relative;
            }
            .record:before {
                content: '';
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 2px;
                background: #2370eb;
            }
        }
    }
    .business_info {
        margin-top: 25px;
        position: relative;
        margin-bottom: 25px;
        padding-left: 30px;
        span:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0;
            height: 100%;
            width: 2px;
            background: #ffa802;
        }
        span {
            font-weight: 600;
            font-size: 14px;
        }
        .hide_btn {
            float: right;
            border-radius: 1px;
            border: 1px solid #2370eb;
            line-height: 1.5;
            font-size: 14px;
            padding: 0 8px;
            margin-right: 15px;
            /* display: inline-block; */
            color: #2370eb;
            cursor: pointer;
        }
    }
    .footer_btn {
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        padding-right: 30px;
        background-color: #fff;
        button:nth-child(2) {
            margin: 0 10px 0 20px;
        }
    }
    .icon-shangchuanshibaixian-copy.red {
        color: #d0021b;
    }
    .icon-chenggong.green {
        color: #64b037;
    }
    em {
        font-style: normal;
        font-size: 14px;
        color: #666666;
    }
}
/deep/ .el-collapse-item__header.is-active {
    border-bottom: 1px solid #ebeef5;
}
.el-collapse {
    border-top: none;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title {
        flex: 1;
        text-align: right;
        padding-right: 10px;
        span {
            color: #2370eb;
            border-radius: 2px;
            border: 1px solid #2370eb;
            padding: 4px 10px;
        }
    }
    .el-collapse-item {
        flex: 1;
    }
    /deep/ .el-collapse-item__wrap {
        height: calc(100% - 61px);
    }
    .el-icon-s-order {
        color: #feb516;
        margin: 0px 13px 0px 17px;
        font-size: 18px;
    }
}
.tool_con {
    color: #fff;
    font-size: 14px;
    > p {
        margin-bottom: 8px;
    }
    p {
        i {
            margin-right: 5px;
        }
    }
    > div {
        border-top: 1px dashed #fff;
        padding-top: 10px;
        > p {
            margin-bottom: 5px;
        }
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    margin-right: 18px;
    cursor: pointer;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.label {
    margin-left: 20px;
}
</style>
